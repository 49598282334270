<template>
  <div class="c-checkout">
    <div class="c-checkout__banner">
      <div class="o-row o-row--sm">
        <div class="o-container--lg">
          <div class="c-checkout__banner--bg">
            <img
              src="@/assets/images/home.jpg"
              alt=""
              srcset=""
            >
          </div>
          <span class="c-checkout__banner--title">{{ $route.meta.title }}</span>
        </div>
      </div>
    </div>

    <div class="o-container">
      <div class="c-checkout__content">
        <div class="grid">
          <div class="row">
            <div class="col-md-5">
              <span class="c-checkout__content--step">01</span>
              <h3>Gegevens</h3>
            </div>
            <div class="col-md-7">
              <div class="grid">
                <div class="row">
                  <div class="col-md-6">
                    <InputComponent label="Bedrijfsnaam" />
                  </div>
                  <div class="col-md-6">
                    <InputComponent label="Btw-nummer" />
                  </div>
                  <div class="col-md-6">
                    <InputComponent label="Naam" />
                  </div>
                  <div class="col-md-6">
                    <InputComponent label="Voornaam" />
                  </div>
                  <div class="col-md-6">
                    <InputComponent label="Emailadres" />
                  </div>
                  <div class="col-md-6">
                    <InputComponent label="Telefoonnummer" />
                  </div>
                  <div class="col-md-8">
                    <InputComponent label="Straat" />
                  </div>
                  <div class="col-md-4">
                    <InputComponent label="Nr" />
                  </div>
                  <div class="col-md-8">
                    <InputComponent label="Gemeente" />
                  </div>
                  <div class="col-md-4">
                    <InputComponent label="Postcode" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <span class="c-checkout__content--step">02</span>
              <h3>Bestelling</h3>
            </div>
            <div class="col-md-7">
              <PremiumCalculator />
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <span class="c-checkout__content--step">03</span>
              <h3>Betalingsmethode</h3>
            </div>
            <div class="col-md-7">
              <!-- WIP, betaalflow zal altijd domiciliëring zijn, radiobutton ontworpen voor andere keuzes -->
              <div class="c-register__options">
                <input
                  id="downpayment"
                  v-model="paymentMethod"
                  type="radio"
                  name="paymentMethod"
                  value="downpayment"
                >
                <label
                  for="downpayment"
                  class="c-payment__option"
                >
                  <div>
                    <div class="c-payment__option--name">
                      <span>Eenmalig</span>
                    </div>
                    <div class="c-payment__option--description">
                      <span>U koopt het aantal tokens voor een periode van 1 jaar, startende vanaf vandaag.</span>
                    </div>
                  </div>
                  <div class="c-payment__option--indicator" />
                </label>
                <input
                  id="directdebit"
                  v-model="paymentMethod"
                  type="radio"
                  name="paymentMethod"
                  value="directdebit"
                >
                <label
                  for="directdebit"
                  class="c-payment__option"
                >
                  <div>
                    <div class="c-payment__option--name">
                      <span>Domiciliëring</span>
                    </div>
                    <div class="c-payment__option--description">
                      <span>u geeft toestemming aan een bedrijf/schuldeiser om uw facturen automatisch te innen bij uw bank.</span>
                    </div>
                  </div>
                  <div class="c-payment__option--indicator" />
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5">
              <div class="grid">
                <div class="row">
                  <div class="col-lg-6">
                    <button class="c-button">
                      Betalen
                    </button>
                  </div>
                  <div class="col-lg-6">
                    <button class="c-button c-button--secondary">
                      Annuleren
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./Checkout.controller.js"></script>
<style lang="scss" src="./Checkout.styles.scss"></style>
